<template>
  <div class="bom">
    <div class="bom-title">{{ title }}</div>
    <div
      style="
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      "
    >
      <div class="last-update">Last Updated : {{ bomLastUpdate }}</div>
      <div v-if="!isPublicPage">
        <ui-button class="primary long" title="Share" @click="copyLink" />
      </div>
    </div>
    <div class="bom-container">
      <div v-if="tableLoading">
        <md-progress-spinner
          class="color_stroke"
          md-mode="indeterminate"
          style="margin: 10px"
        ></md-progress-spinner>
      </div>
      <bom-category-item
        v-if="!tableLoading"
        :isAuthority="isAuthority"
        :headerHeight="headerHeight"
        :inquiryId="inquiryId"
        :bomData="bomData.fabric"
        :category="categories[3]"
        :baseUrl="baseUrl"
        @refresh="getBomData"
        :measurements="measurements"
        :suppliers="suppliers"
        :compositions="compositions"
        @refresh-log="refreshLog"
        @change-tab="changeTab"
        @update-suppliers="updateSuppliers"
        @change-main-fabric="changeMainFabric"
      />
      <bom-category-item
        v-if="!tableLoading"
        :isAuthority="isAuthority"
        :headerHeight="headerHeight"
        :inquiryId="inquiryId"
        :bomData="bomData.sewing_item"
        :category="categories[0]"
        :baseUrl="baseUrl"
        @refresh="getBomData"
        :measurements="measurements"
        :suppliers="suppliers"
        :compositions="compositions"
        @refresh-log="refreshLog"
        @change-tab="changeTab"
        @update-suppliers="updateSuppliers"
      />
      <bom-category-item
        v-if="!tableLoading"
        :isAuthority="isAuthority"
        :headerHeight="headerHeight"
        :inquiryId="inquiryId"
        :bomData="bomData.label"
        :category="categories[1]"
        :baseUrl="baseUrl"
        @refresh="getBomData"
        :measurements="measurements"
        :suppliers="suppliers"
        :compositions="compositions"
        @refresh-log="refreshLog"
        @change-tab="changeTab"
        @update-suppliers="updateSuppliers"
      />
      <bom-category-item
        v-if="!tableLoading"
        :isAuthority="isAuthority"
        :headerHeight="headerHeight"
        :inquiryId="inquiryId"
        :bomData="bomData.packing_item"
        :category="categories[2]"
        :baseUrl="baseUrl"
        @refresh="getBomData"
        :measurements="measurements"
        :suppliers="suppliers"
        :compositions="compositions"
        @refresh-log="refreshLog"
        @change-tab="changeTab"
        @update-suppliers="updateSuppliers"
      />
      <bom-category-item
        v-if="!tableLoading"
        :isAuthority="isAuthority"
        :headerHeight="headerHeight"
        :inquiryId="inquiryId"
        :bomData="bomData.other"
        :category="categories[4]"
        :baseUrl="baseUrl"
        @refresh="getBomData"
        :measurements="measurements"
        :suppliers="suppliers"
        :compositions="compositions"
        @refresh-log="refreshLog"
        @change-tab="changeTab"
        @update-suppliers="updateSuppliers"
      />
    </div>
  </div>
</template>

<script>
/* eslint-disable @typescript-eslint/camelcase */
// import CostSheetBreakdowns from "../../components/Inquiry/CostSheetBreakdowns.vue";
import restAdapter from "../../restAdapter";
import { bomDataBus } from "../../utils/bomDataBus";
import * as conf from "../../conf.yml";
import utils from "../../utils";
import { isImageFile } from "../../utils/media-type";
import notification from "../../notification";
import auth from "../../auth";
import routes from "../../router/routes";
import store from "@/store";
import BomCategoryItem from "./BomCategoryItem.vue";
import { StatusCodes } from "http-status-codes";

export default {
  name: "BomComponent",
  components: {
    // CostSheetBreakdowns,
    BomCategoryItem,
  },
  created() {
    bomDataBus.$on("last-update", this.updateLastUpdate);
    bomDataBus.$on("force-refresh", this.getBomData);
  },
  computed: {
    isPublicPage() {
      return this.$route.name === routes.PUBLIC_BOM;
    },
    isBrandAdminOrManager() {
      return (
        auth.user.role === "BRAND_ADMIN" ||
        auth.user.role === "BRAND_ACCOUNT_MANAGER"
      );
    },
    bomAuthority() {
      return auth.user.role === "BRAND_USER" ? "user" : "admin";
    },
  },
  watch: {
    "$store.state.inquirySuppliersUpdated": {
      handler: function () {
        this.getSupplierDetails();
      },
      deep: true,
    },
  },
  data: () => ({
    inquiryId: "",
    styleNumber: "",
    baseUrl: "",
    bomData: {},
    compositions: [],
    isAuthority: false,
    title: "Bill of Materials - BOM",
    defaultImage: null,
    selectedSupplierId: null,
    selectedFactory: null,
    showFactorySelect: false,
    headerHeight: 0,
    bomHeaders: [
      { name: "Item", colspan: 2 },
      { name: "Image", colspan: 2 },
      { name: "Composition", colspan: 3 },
      { name: "Measuring Unit", colspan: 2 },
      { name: "Consumption per garment", colspan: 2 },
      { name: "Total", colspan: 2 },
      { name: "Total Consumption", colspan: 2 },
      { name: "Supplier", colspan: 4 },
      { name: "Description", colspan: 3 },
    ],
    // data: [],
    acceptedThumbnailTypes: utils.acceptedThumbnailTypes,
    bulkBreakdown: false,
    loading: false,
    tableLoading: false,
    bomLastUpdate: null,
    factories: [],
    suppliers: {
      data: [],
    },
    data: {
      suppliers: [],
    },
    measurements: [],
    categories: [
      {
        name: "sewing_item",
        title: "Sewing Item",
      },
      {
        name: "label",
        title: "Label",
      },
      {
        name: "packing_item",
        title: "Packing Item",
      },
      {
        name: "fabric",
        title: "Fabric",
      },
      {
        name: "other",
        title: "Other",
      },
    ],
  }),

  mounted() {
    this.loading = true;
    this.tableLoading = true;
    if (this.isPublicPage) {
      this.styleNumber = this.$route.params.styleNumber;
    } else {
      this.inquiryId = this.$route.params.id;
    }
    this.baseUrl = conf.default.server.host;
    this.getBomData();
    this.getMeasurements();
    this.getSupplierDetails();
  },

  methods: {
    copyLink() {
      this.$emit("copy-link");
    },
    changeTab(tab) {
      this.$emit("change-tab", tab);
    },
    updateLastUpdate(lastUpdate) {
      this.bomLastUpdate = lastUpdate;
    },

    refreshLog() {
      store.commit(
        "SET_BOM_REFRESH_TOGGLE",
        !this.$store.state.bomRefreshToggle
      );
      bomDataBus.$emit("refresh-log");
    },

    changeMainFabric(bomItemId, value) {
      this.bomData.fabric = this.bomData.fabric.map((item) => {
        if (item.id === bomItemId) {
          item.is_main_fabric = value;
        } else {
          item.is_main_fabric = false;
        }
        return item;
      });
    },

    getBomData() {
      this.refreshLog();
      restAdapter
        .get(
          `/api/inquiries/${
            this.isPublicPage ? `public/${this.styleNumber}` : this.inquiryId
          }/bom`
        )
        .then((response) => {
          this.bomData = this.processBomData(response.data.bom_data);
          this.compositions = response.data.compositions;
          this.defaultImage = response.data.default_image;
          this.loading = false;
          this.tableLoading = false;
          this.isAuthority = response.data.is_authority;
        })
        .catch((error) => {
          if (error.response.status === StatusCodes.BAD_REQUEST) {
            this.$emit("invalid-style");
          }
        });
    },

    processBomData(data) {
      const processedBomData = {};

      this.categories.forEach((category) => {
        if (!data[category.name]) return;
        processedBomData[category.name] = data[category.name].map((item) => {
          const factories = {};
          item.supplier_ids.forEach((supplierId) => {
            const records = item.suppliers.filter(
              (supplier) => supplier.supplier_id == supplierId
            );
            if (records.length > 0) {
              factories[supplierId] = records.map((record) => {
                if (record.used_supplier_as_factory) {
                  return "useCompanyAsFactory";
                }
                return record.factory_id;
              });
            } else {
              factories[supplierId] = [];
            }
          });

          return {
            ...item,
            factories: factories,
          };
        });
      });

      return processedBomData;
    },

    getIsSupplier() {
      return auth.user.userType === "supplier";
    },

    getMeasurements() {
      restAdapter.get(`/api/unit_of_measurements`).then((response) => {
        this.measurements = response.data;
      });
    },

    getSupplierDetails() {
      restAdapter
        .get(
          `/api/inquiries/${
            this.isPublicPage ? `public/${this.styleNumber}` : this.inquiryId
          }/assigned_suppliers`
        )
        .then((response) => {
          this.suppliers = response.data.inquiry_assigned_suppliers;
        });
    },

    updateSuppliers(suppliers) {
      this.suppliers = suppliers;
    },

    openTechPack() {
      this.$router.push({ name: "Tech Pack Development" });
    },

    isImage(file) {
      return isImageFile(file);
    },

    openFileInput() {
      document.getElementById("fileInput").click();
    },

    isSupportedFileType(filename) {
      const extension =
        filename.match(/\.[0-9a-z]+$/i) &&
        filename.match(/\.[0-9a-z]+$/i)[0].toLowerCase();
      const supportedFileTypes = this.acceptedThumbnailTypes.split(",");
      return supportedFileTypes.includes(extension);
    },

    uploadThumbnail(file) {
      this.loading = true;
      if (file.length !== 0) {
        const formData = new FormData();
        formData.append("file[]", file[0]);
        restAdapter
          .post("/api/inquiries/files_upload/" + this.inquiryId, formData)
          .then((response) => {
            this.setDefaultImage(response.data.attachment_id);
          })
          .catch((error) => {
            notification.error("Something went wrong.");
          });
      }
    },

    setDefaultImage(id) {
      restAdapter
        .post(`/api/inquiries/${this.inquiryId}/set_default_image/`, {
          default_image: id,
        })
        .then(() => {
          notification.success("Image set as default thumbnail successfully");
          this.loading = false;
          this.defaultImage = id;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.bom {
  min-height: 75vh;
}

.bom-title {
  text-align: center;
  font-size: 25px;
  background-color: #473068;
  color: #fff;
  font-weight: bold;
  padding: 10px 0px;
}

.public-button {
  color: white !important;
  border: 2px solid;
  border-radius: 8px;
  position: absolute;
  right: 0;
  top: -15px;
}

.qr-code-comp {
  padding: 10px;
}

.url-div {
  border: 0.5px solid;
  border-color: #501d5f !important;
  border-radius: 8px;
  padding: 6px;
  text-align: left;
  width: fit-content;
  margin-bottom: 40px;
}

.url-span {
  color: #c2aae8;
  padding: 2px 5px 2px 5px;
  margin-right: 5px;
}

.breakdown-container {
  margin: 25px 5px;
}

.breakdown-btn {
  background-color: #ea7a66;
  color: #fff;
  border-radius: 5px;
  margin-left: 5px;
}

.image {
  object-fit: cover;
  height: 140px;
  width: 150px;
}

.last-update {
  text-align: left;
  background-color: #eef1fa;
  border-radius: 5px;
  width: fit-content;
  height: fit-content;
  padding: 2px 10px;
  color: #473068;
  font-size: 18px;
}

.table-header {
  position: sticky;
  top: 0;
  z-index: 6;
}

.other-th {
  outline: 0.5px solid #473068;
  text-align: center;
  height: 50px;
  background-color: #47c0be;
  color: #442d65;
  font-weight: 600;
  font-size: 14px;
  padding: 0px 3px;
  word-wrap: break-word;
}

.status-th {
  outline: 0.5px solid #473068;
  text-align: center;
  height: 50px;
  background-color: #473068;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  padding: 0px 3px;
  word-break: break-all;
}

.actions-td {
  border: none !important;
  width: 60px !important;
  background-color: #fff;
}

.add-img {
  margin: -5px;
  background: $paginateText;
  width: 25px;
  height: 25px;
  float: right;
  border-radius: 50%;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  display: flex;
  border: 0.5px solid #442d64;
}

.add-img:hover {
  filter: drop-shadow(0px 4px 4px rgba(72, 49, 105, 0.15));
}

.add-img-icon {
  font-size: 15px;
  vertical-align: unset;
}

.thumbnail-actions-container {
  right: 20px;
  top: 20px;
  width: 90px;
  text-align: left;
  padding: 5px;
  height: auto;
  z-index: 8;
  background: rgba(255, 255, 255, 0.85);
  box-shadow: 2.96703px 13.8462px 11.8681px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  position: absolute;
}

.color_stroke::v-deep .md-progress-spinner-circle {
  stroke: #492a67 !important;
}

.bom-container {
  margin: 20px 0;
}

.action-item {
  cursor: pointer;
  width: 100%;
  display: block;
}

.action-item:hover {
  background-color: white;
  color: #442d64;
}
</style>
